import React from 'react';
import './navigation.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

interface State {
  backgroundColor: string;
}

class NavigationComponent extends React.Component<{}, State> {
  constructor(props : any) {
    super(props);
    this.state = {
      backgroundColor: "navbar-transparent"
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const backgroundColor = window.scrollY < 20 ? "navbar-transparent" : "navbar-opaque";
      this.setState({ backgroundColor: backgroundColor });
    });
  }

  render() {
    const { backgroundColor } = this.state;

    return (
      <Navbar className={backgroundColor} fixed="top" expand="lg" collapseOnSelect>
        <Navbar.Brand href="/">
          <img
            src="logo.png"
            height="50"
            className="d-inline-block align-top"
            alt="YPKY logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Link target="_blank" href="https://help.ypky.io">Startup Hotline</Nav.Link>
                <Nav.Link target="_blank" href="https://kb.ypky.io">Knowledge Base</Nav.Link>
            </Nav>
            <Nav>
                <Nav.Link href="https://www.linkedin.com/company/ypky" target="_blank">
                    <img
                        src="whatsapp.png"
                        className="d-inline-block align-top"
                        alt="YPKY WhatsApp"
                    />
                    &nbsp;
                    WhatsApp
                </Nav.Link>
                <Nav.Link href="https://www.linkedin.com/company/ypky" target="_blank">
                    <img
                        src="linkedin.png"
                        className="d-inline-block align-top"
                        alt="YPKY LinkedIn"
                    />
                    &nbsp;
                    LinkedIn
                </Nav.Link>
                <Nav.Link href="https://discord.gg/Ey5AErf" target="_blank">
                    <img
                        src="discord.png"
                        className="d-inline-block align-top"
                        alt="YPKY Discord"
                    />
                    &nbsp;
                    Discord
                </Nav.Link>
                <Nav.Link href="http://eepurl.com/hdmy7r" target="_blank">
                    <img
                        src="newsletter.png"
                        className="d-inline-block align-top"
                        alt="YPKY Newsletter"
                    />
                    &nbsp;
                    Newsletter
                </Nav.Link>
            </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavigationComponent;
