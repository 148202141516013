import React from 'react';
import './map.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as THREE from 'three';

class MapComponent extends React.Component {

  private mount = React.createRef<HTMLDivElement>();
  private scene : THREE.Scene;
  private camera : THREE.PerspectiveCamera;
  private renderer : THREE.WebGLRenderer;

  constructor(props: any) {
    super(props);

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 1, 1000);
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });
  }

  componentDidMount() {

    if(this.mount.current) {
      var elem = this.mount.current;

      this.renderer.setSize(elem.offsetWidth, elem.offsetHeight);
    	this.camera.aspect = elem.offsetWidth / elem.offsetHeight;
      this.mount.current.appendChild(this.renderer.domElement);
    }

    this.scene.background = new THREE.Color(0x394049);
    this.scene.background = new THREE.Color(0xffffff);

    this.scene.add(this.camera);

    var texture = new THREE.TextureLoader().load("map.png", () => {
      var geometry =  new THREE.PlaneBufferGeometry(1.3, 1.3);
      var material =  new THREE.MeshStandardMaterial({color: 0xff333f});
      var material =  new THREE.MeshStandardMaterial({color: 0xffffff});
      var group =     new THREE.Group();

      var canvas = document.createElement("canvas");
      canvas.width =  texture.image.width;
      canvas.height = texture.image.height;

      var ctx =       canvas.getContext("2d");
      ctx.drawImage(texture.image, 0, 0);

      var rectAreaLight = new THREE.RectAreaLight( 0xdddddd, 1.0, 1000, 1000);
      rectAreaLight.position.set( 0, -100, 200 );
      rectAreaLight.lookAt( 0, 0, 0 );

      var ambientLight = new THREE.AmbientLight( 0xdddddd );
      this.scene.add(ambientLight);

      var image =     ctx.getImageData(0, 0, canvas.width, canvas.height);

      for(var y = 0; y < image.height; y += 2) {
        for(var x = 0; x < image.width; x += 2) {
          if(image.data[(x * 4 + y * 4 * image.width) + 3] > 128) {

            var mesh = new THREE.Mesh(geometry, material);

            mesh.position.x = x - image.width / 2;
            mesh.position.y = -y + image.width / 2;
            mesh.position.z = y / 300;

            mesh.updateMatrix();
            group.add(mesh);
          }
        }
      }

      var box = new THREE.Box3().setFromObject(group);

      group.translateY( -0.5 * (box.max.y + box.min.y) + 25);
      group.translateX( -0.5 * (box.max.x + box.min.x) );

      this.scene.add(group);

      requestAnimationFrame(render);
	  });


    var curve = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( -6, 40, 2 ),
    	new THREE.Vector3( 7, -18, 24 ),
    	new THREE.Vector3( 20, -76, 2 )
    );

    var points = curve.getPoints(32);
    var geometry = new THREE.BufferGeometry().setFromPoints(points);

    var material = new THREE.LineBasicMaterial({
      color: 0xff333f,
      linewidth: 7,
      transparent: true,
      opacity: 0.5
    });


    var curveObject = new THREE.Line(geometry, material);
    this.scene.add(curveObject);

    var curve = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( -6, 40, 2 ),
    	new THREE.Vector3( -79, 25, 24 ),
    	new THREE.Vector3( -158, 10, 2 )
    );

    var points = curve.getPoints(32);
    var geometry = new THREE.BufferGeometry().setFromPoints(points);

    var curveObject = new THREE.Line(geometry, material);
    this.scene.add(curveObject);

    var curve = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( -6, 40, 2 ),
    	new THREE.Vector3( 7, 46, 24 ),
    	new THREE.Vector3( 20, 52, 2 )
    );

    var points = curve.getPoints(32);
    var geometry = new THREE.BufferGeometry().setFromPoints(points);

    var curveObject = new THREE.Line(geometry, material);
    this.scene.add(curveObject);

    var curve = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( -6, 40, 2 ),
    	new THREE.Vector3( -6, 40, 12 ),
    	new THREE.Vector3( -10, 40, 2 )
    );

    var points = curve.getPoints(32);
    var geometry = new THREE.BufferGeometry().setFromPoints(points);

    var curveObject = new THREE.Line(geometry, material);
    this.scene.add(curveObject);

    var curve = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( -6, 40, 2 ),
    	new THREE.Vector3( 2, 40, 12 ),
    	new THREE.Vector3( 10, 40, 2 )
    );

    var points = curve.getPoints(32);
    var geometry = new THREE.BufferGeometry().setFromPoints(points);

    var curveObject = new THREE.Line(geometry, material);
    this.scene.add(curveObject);


    this.camera.position.set(25, -200, 300);
    this.camera.lookAt(-10, 0, 0);

    var render = () => {
      requestAnimationFrame(render);
      this.renderer.render(this.scene, this.camera);
    }

    window.addEventListener( 'resize', () => {
  		this.camera.aspect = elem.offsetWidth / elem.offsetHeight;
  		this.camera.updateProjectionMatrix();
  		this.renderer.setSize(elem.offsetWidth, elem.offsetHeight);
    });

  }

  render() {
    return (
      <Row className="no-gutters">
        <Col xs={12} className="map-component no-gutters">
          <p className="pay-off">
            YPKY taps you into the
            resources of the<br />
            Global Startup Scene.
          </p>
          <div ref={this.mount}></div>
        </Col>
      </Row>
    );
  }
}

export default MapComponent;
