import React from 'react';
import './App.css';

import Container from 'react-bootstrap/Container';

import NavigationComponent from './components/navigation/navigation';
import MapComponent from './components/map/map';

import ReactGA from 'react-ga';
import CookieConsent, {Cookies} from "react-cookie-consent";


const trackingId = "UA-153064584-1";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <div>

            <CookieConsent
                location={"bottom"}
                cookieName="YPKYCookieConsent"
                expires={30}
                buttonStyle={{
                    background: "#ff333f",
                    color: "white"
                }}
                style={{
                    zIndex: "20000000000"
                }}
            >
                This website collects information through cookies for <b>Google Analytics</b>.
            </CookieConsent>

            <Container fluid>
                <NavigationComponent/>
            </Container>
            <MapComponent/>

        </div>
    );
}

export default App;
